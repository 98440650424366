<template>
    <div class="content">
        <template v-if="dataLoading">
            <div class="details-content" v-if="info.title">
                <div class="details-image-video" v-if="info.img_url || info.video_path">
                    <template v-if="info.video_path">
                        <div style="height: 675px;position: relative">
                            <video id="player-container-id" style="width:100%; height:100%;" preload="auto" playsinline webkit-playsinline></video>
                        </div>
                    </template>
                    <img :src="info.img_url" mode="widthFix" v-else />
                </div>
                <div class="details-title2">{{ info.title }}</div>
                <div class="details-other">{{ info.author }}&nbsp;&nbsp;&nbsp; {{ info.company }}</div>
                <div class="details-parse" v-html="info.content"></div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                info: {},
                dataLoading: false,
                videoData: {}
            }
        },
        mounted() {
            this.getDetails()
        },
        methods: {
            getDetails() {
                this.$axios.post(this.apiUrl.SurgicalQueryInfo, {id: this.$route.params.id}).then(res => {
                    this.info = res.data;
                    this.play(this.info.file_id)
                    this.dataLoading = true
                })
            },
            play(file_id) {
                this.$axios.post(this.apiUrl.getVideoUrl_One, {
                    fileid: file_id
                }).then(res => {
                    this.videoData.file_id = file_id;
                    this.videoData.psign = res.data.signpc;
                    this.tcplayer = TCPlayer('player-container-id', {
                        fileID: this.videoData.file_id,
                        appID: '1258924221',
                        psign: this.videoData.psign,
                        poster: this.info.img_url,
                        autoplay: true,
                        //其他参数请在开发文档中查看
                        plugins: {
                            ProgressMarker: true,
                            ContinuePlay: {
                                auto: true,
                                text:'上次播放至 '
                            }
                        }
                    });
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
    .details-image-video{
        /deep/ .uni-video-cover-duration{
            display: none;
        }
    }

    .details-content{
        width: 1200px;
        margin: 50px auto;
        .details-title1{
            font-size: 20px;
            color: #111;
            margin-top: 5px;
        }
        .details-title2{
            font-size: 20px;
            color: #111;
            margin-top: 15px;
        }
        .details-other{
            color: #999;
            font-size: 14px;
            margin: 5px 0 10px;
        }
        .details-info{
            font-size: 10px;
            color: #6F7884;
            margin-top: 4px;
            .details-info-label{
                margin-right: 30px;
                span{
                    margin-right: 5px;
                }
            }
        }
        .details-parse{
            margin-top: 20px;
        }
    }
    .details-image-video{
        overflow: hidden;
        image{
            width: 100%;
        }
    }
</style>
